import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "capitalize",
  standalone: true,
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return "";

    const capitalizedText = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

    return capitalizedText.replace(/(\d)/, " $1");
  }
}
