import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@services";
import { type BadgeStatus, StatusBadgeComponent } from "../status-badge/status-badge.component";
import type { ChallengeStatus } from "../../model";

@Component({
  selector: "app-challenge-status",
  standalone: true,
  imports: [StatusBadgeComponent],
  templateUrl: "./challenge-status.component.html",
  styleUrls: ["./challenge-status.component.scss"],
})
export class ChallengeStatusComponent implements OnInit {
  @Input({ required: true }) status: ChallengeStatus;

  label = "";
  badgeStatus: BadgeStatus;

  statusMap: Record<ChallengeStatus, BadgeStatus> = {
    PASSED: "success",
    ACTIVE: "warning",
    BREACHED: "danger",
    BLOCKED: "danger",
  };

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.badgeStatus = this.statusMap[this.status];
    this.translateService.selectTranslationObject(`shared.challengeStatus`).subscribe((label) => {
      this.label = label[this.status];
    });
  }
}
