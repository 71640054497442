import { Injectable } from "@angular/core";
import snsWebSdk from "@sumsub/websdk";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { switchMap } from "rxjs/operators";
import { UserService } from "./user.service";

@Injectable({ providedIn: "root" })
export class KycService {
  constructor(
    private userService: UserService,
    private toastService: ToastrService,
  ) {}

  private getNewAccessToken() {
    return lastValueFrom(
      this.userService.generateKycSdkToken().pipe(switchMap((response) => response.accessToken)),
    );
  }

  launchWebSdk(accessToken: string, userInfo: { email: string; phone: string }) {
    const snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => this.getNewAccessToken())
      .withConf({ lang: "en", email: userInfo.email, phone: userInfo.phone })
      .withOptions({ addViewportTag: true, adaptIframeHeight: true })
      .on("idCheck.onError", (error) => this.toastService.error(error.error))
      .build();

    snsWebSdkInstance.launch("#sumsub-websdk-container");
  }
}
