<button
  class="info-tooltip"
  tooltipClass="custom-tooltip"
  [placement]="position"
  [ngbTooltip]="tooltipContent"
  (hidden)="handleTooltipVisibilityChange(false)"
  (shown)="handleTooltipVisibilityChange(true)"
>
  @if (isTooltipVisible) {
    <img src="assets/svg/icon-info-active.svg" alt="" />
  } @else {
    <img src="assets/svg/icon-info-v2.svg" alt="" />
  }
</button>

<ng-template #tooltipContent>
  <ng-content></ng-content>
</ng-template>
