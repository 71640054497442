import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { CommonService } from "@services";
import { getCountries, getCountriesError, getCountriesSuccess } from "./countries.actions";

@Injectable()
export class CountriesEffects {
  getCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCountries),
      mergeMap(() =>
        this.commonService.getCountries().pipe(
          map((response) => getCountriesSuccess({ countries: response.data })),
          catchError((error) => of(getCountriesError({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonService: CommonService,
  ) {}
}
