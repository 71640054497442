import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@services";
import { getVoucherTitle } from "@utils";
import type { Voucher } from "@models";

@Pipe({
  name: "voucherTitle",
  standalone: true,
  pure: false,
})
export class VoucherTitlePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: Voucher): string {
    return getVoucherTitle(value, this.translateService);
  }
}
