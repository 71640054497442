import { Component } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";

@Component({
  selector: "app-accordion",
  templateUrl: "./accordion.component.html",
  styleUrls: ["./accordion.component.scss"],
  standalone: true,
  imports: [MatExpansionModule],
})
export class AccordionComponent {}
