import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router, RouterOutlet } from "@angular/router";
import { FooterComponent } from "../../components/footer/footer.component";
import { HeaderComponent } from "../../components/header/header.component";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
  standalone: true,
  imports: [HeaderComponent, RouterOutlet, FooterComponent],
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild("canvas", { static: true }) canvasRef: ElementRef;

  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private stars: any[];
  private lastScroll = 0;
  private s = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  isCookiesAccepted: boolean;
  isTouchDevice = false;
  queryParams: Params;

  constructor(
    private cookieService: CookieService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((query) => {
      if (query.affiliateId) {
        this.queryParams = query;
      }
    });
  }

  ngOnInit() {
    this.isCookiesAccepted = this.cookieService.get("isCookieAccepted") === "true";

    this.breakpointObserver.observe(["(max-width: 1024px)"]).subscribe((result) => {
      if (result.matches) {
        this.isTouchDevice = true;
      }
    });
    if (this.isTouchDevice) return;

    this.canvas = this.canvasRef.nativeElement;
    this.ctx = this.canvas.getContext("2d") ?? new CanvasRenderingContext2D();
    this.canvas.width = this.s.width;
    this.canvas.height = this.s.height;
    this.stars = [];

    this.generateStars(250);
    this.animate();

    window.addEventListener("scroll", this.handleScroll.bind(this));
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  acceptCookie() {
    this.cookieService.set("isCookieAccepted", "true", 30);
    this.isCookiesAccepted = true;
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  handleScroll() {
    setTimeout(async () => {
      this.lastScroll = window.scrollY;
    }, 50);
  }

  handleResize() {
    this.s.width = window.innerWidth;
    this.s.height = window.innerHeight;
    this.canvas.width = this.s.width;
    this.canvas.height = this.s.height;
  }

  generateStars(numStars: number) {
    for (let t = 0; t < numStars; t++) {
      this.stars.push({
        x: Math.random() * this.s.width - this.s.width / 2,
        y: Math.random() * this.s.height - this.s.height / 2,
        z: Math.random() * 1000,
        d: Math.random() * 2 + 4,
        opacity: this.opacity(0.5, 1),
      });
    }
  }

  opacity(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  animate() {
    if (this.stars.length === 0) return;

    this.ctx.clearRect(0, 0, this.s.width, this.s.height);
    const scrollTop = window.scrollY;
    const delta = 0.3 + Math.abs(this.lastScroll - scrollTop) / 5;
    const speed = delta;

    for (const element of this.stars) {
      element.z -= speed;

      if (element.z <= 0) {
        element.z = 1000;
        element.x = Math.random() * this.s.width - this.s.width / 2;
        element.y = Math.random() * this.s.height - this.s.height / 2;
      }
    }

    for (const element of this.stars) {
      const e = 1000 / element.z;
      const h = element.x * e + this.s.width / 2;
      const a = element.y * e + this.s.height / 2;
      const o = (1 - element.z / 1000) * element.d;
      let i = 1000 / (element.z + speed);
      if (i < 1) {
        i = 1;
      }
      const r = element.x * i + this.s.width / 2;
      const n = element.y * i + this.s.height / 2;

      this.ctx.beginPath();
      this.ctx.strokeStyle = "#878787";
      this.ctx.lineWidth = o / 1.5;
      this.ctx.moveTo(r, n);
      this.ctx.lineTo(h, a);
      this.ctx.closePath();
      this.ctx.stroke();

      this.ctx.beginPath();
      this.ctx.arc(h, a, o / 1.5, 0, 2 * Math.PI);
      this.ctx.fillStyle = "#878787";
      this.ctx.fill();
    }

    requestAnimationFrame(() => this.animate());
  }

  navigateToUrl(url: string) {
    if (this.queryParams?.affiliateId) {
      this.router.navigate([url], { queryParams: this.queryParams });
    } else {
      this.router.navigate([url]);
    }
  }
}
