import { createSelector } from "@ngrx/store";
import type { AppState } from "@store";

const selectUserState = (state: AppState) => state.user;

export const selectCurrentUser = createSelector(selectUserState, (state) => state.userInfo);

export const selectIsUserAuthenticated = createSelector(
  selectUserState,
  (state) => state.isAuthenticated,
);

export const selectUserTokens = createSelector(selectUserState, (state) => state.tokens);
