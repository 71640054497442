import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { TranslateService } from "../service/translate.service";

@Injectable({
  providedIn: "root",
})
export class LanguageResolver implements Resolve<void> {
  constructor(
    private translocoService: TranslocoService,
    private translateService: TranslateService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const lang = route.paramMap.get("lang");
    if (lang === "es" || lang === "tr") {
      this.translocoService.setActiveLang(lang);
      this.translateService.setLanguage(lang);
    } else {
      this.translocoService.setActiveLang("en");
      this.translateService.setLanguage("en");
    }
  }
}
