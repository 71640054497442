import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-icon-button",
  templateUrl: "./icon-button.component.html",
  styleUrls: ["./icon-button.component.scss"],
  standalone: true,
})
export class IconButtonComponent {
  @Input() icon: string;
  @Input() ariaLabel: string;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter<void>();

  handleOnClick() {
    this.onClick.emit();
  }
}
