import { Injectable, isDevMode } from "@angular/core";
import { type TranslocoConfig, TranslocoLoader, TranslocoService } from "@ngneat/transloco";

export type Language = "en" | "tr" | "es";
const availableLangs: Language[] = ["en", "tr", "es"];

export function loadTranslations(translocoService: TranslocoService) {
  const lang = localStorage.getItem("language") ?? translocoService.getActiveLang();
  translocoService.setActiveLang(lang);

  return () => translocoService.load(lang);
}

export const TRANSLOCO_CONFIG: Partial<TranslocoConfig> = {
  availableLangs,
  defaultLang: "en",
  fallbackLang: "en",
  reRenderOnLangChange: true,
  prodMode: !isDevMode(),
  missingHandler: {
    logMissingKey: true,
    useFallbackTranslation: true,
    allowEmpty: false,
  },
};

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  getTranslation(lang: string) {
    return import(`./../assets/i18n/${lang}.json`).then((response) => response.default);
  }
}
