import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import { ChallengeService } from "@services";
import {
  getActiveChallenges,
  getActiveChallengesError,
  getActiveChallengesSuccess,
  getChallenges,
  getChallengesError,
  getChallengesSuccess,
} from "./challenges.actions";

@Injectable()
export class ChallengesEffects {
  getChallenges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChallenges),
      mergeMap(() =>
        this.challengeService.getUserChallenges().pipe(
          map((response) => getChallengesSuccess({ challenges: response.data })),
          catchError((error) => of(getChallengesError({ error }))),
        ),
      ),
    ),
  );

  getActiveChallenges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getActiveChallenges),
      mergeMap(() =>
        this.challengeService.getUserActiveChallenges().pipe(
          map((response) => getActiveChallengesSuccess({ challenges: response })),
          catchError((error) => of(getActiveChallengesError({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private challengeService: ChallengeService,
  ) {}
}
