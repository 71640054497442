<div class="footer" *transloco="let t; read: 'landing.footer'">
  <div class="Footer_inner">
    <div class="footer_top d-flex flex-column flex-sm-row align-items-sm-center">
      <p class="Footer_text d-none d-md-block">© 2024 BrightFunded</p>
      <nav class="Footer_links">
        <a class="Footer_link" href="javascript:;" [routerLink]="['/privacy-policy']">
          {{ t("link.1") }}
        </a>
        <a class="Footer_link" href="javascript:;" [routerLink]="['/terms-conditions']">
          {{ t("link.2") }}
        </a>
      </nav>
      <nav class="Footer_socialLinks ms-sm-auto mt-3 mt-sm-0">
        <a
          href="{{ social.discord }}"
          target="_blank"
          rel="noopener noreferrer nofollow"
          class="Footer_link"
        >
          <img src="assets/svg/icon-discord.svg" alt="" />
        </a>
        <a
          href="{{ social.telegram }}"
          target="_blank"
          rel="noopener noreferrer nofollow"
          class="Footer_link"
        >
          <img src="assets/svg/icon-telegram.svg" alt="" />
        </a>
        <a
          href="{{ social.instagram }}"
          target="_blank"
          rel="noopener noreferrer nofollow"
          class="Footer_link"
        >
          <img src="assets/svg/icon-instagram.svg" alt="" />
        </a>
        <a
          href="{{ social.twitter }}"
          target="_blank"
          rel="noopener noreferrer nofollow"
          class="Footer_link"
        >
          <img src="assets/svg/icon-twitter-v2.svg" alt="" />
        </a>
      </nav>
    </div>
    <div class="footer_bottom">
      <div class="d-flex">
        <div class="me-3">
          <p class="mb-0">
            {{ t("shortDescription") }}
          </p>
        </div>

        <button
          class="Footer_expandBtn"
          [ngClass]="{ Footer_expandBtn_open: textVisible }"
          (click)="additionalText()"
        >
          <img src="assets/svg/icon-chevron.svg" alt="" width="12" />
        </button>
      </div>
      <div
        class="Footer_additionalText"
        [@expandCollapseAnimation]="textVisible ? 'expanded' : 'collapsed'"
      >
        <p class="me-4 mb-0">
          {{ t("longDescription") }}
        </p>
      </div>
      <div id="targetElement"></div>
      <p class="Footer_text mt-3 m-0 d-block d-md-none" style="color: #727581; font-size: 13px">
        © 2024 BrightFunded
      </p>
    </div>
  </div>
</div>
