import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@services";
import { getVoucherDescription } from "@utils";
import type { Voucher } from "@models";

@Pipe({
  name: "voucherDescription",
  standalone: true,
  pure: false,
})
export class VoucherDescriptionPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: Voucher): string[] {
    return getVoucherDescription(value, this.translateService);
  }
}
