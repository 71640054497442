import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import { UserService, VoucherService } from "@services";
import {
  getUser,
  getUserError,
  getUserSuccess,
  getUserTokens,
  getUserTokensError,
  getUserTokensSuccess,
} from "./user.actions";

@Injectable()
export class UserEffects {
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUser),
      mergeMap(() =>
        this.userService.getCurrentUser().pipe(
          map((user) => getUserSuccess({ user })),
          catchError((error) => of(getUserError({ error }))),
        ),
      ),
    ),
  );

  getUserTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserTokens),
      mergeMap(() =>
        this.voucherService.getUserAvailableTokens().pipe(
          map((response) => getUserTokensSuccess({ tokens: response.amount })),
          catchError((error) => of(getUserTokensError({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private voucherService: VoucherService,
  ) {}
}
