import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@services";
import type { ChallengeAddOn } from "@models";
import { getAddOnTitle } from "@utils";

@Pipe({
  name: "addOnTitle",
  standalone: true,
  pure: false,
})
export class AddOnTitlePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(addOn: ChallengeAddOn): string {
    return getAddOnTitle(addOn, this.translateService);
  }
}
