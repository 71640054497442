import { Component, Input } from "@angular/core";
import { ActivatedRoute, Params, Router, RouterLink, RouterLinkActive } from "@angular/router";
import { CommonService } from "../../common.service";
import { NgOptimizedImage } from "@angular/common";

@Component({
  selector: "app-side-nav-item",
  templateUrl: "./side-nav-item.component.html",
  styleUrls: ["./side-nav-item.component.scss"],
  standalone: true,
  imports: [RouterLinkActive, RouterLink, NgOptimizedImage],
})
export class SideNavItemComponent {
  @Input({ required: true }) label = "";
  @Input({ required: true }) navigateTo = "";
  @Input({ required: false }) image = "";

  queryParams: Params;

  constructor(
    private router: Router,
    public commonService: CommonService,
    route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((query) => {
      if (query.affiliateId) {
        this.queryParams = query;
      }
    });
  }

  ngOnInit(): void {
    if (this.image === "") {
      this.image = this.navigateTo;
    }
  }

  navigateToUrl(url: string) {
    if (this.queryParams?.affiliateId) {
      this.router.navigate([url], { queryParams: this.queryParams });
    } else {
      this.router.navigate([url]);
    }
  }
}
