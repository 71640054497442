import { createAction, props } from "@ngrx/store";
import type { GroupedChallenges, NewChallenge } from "@models";

export const getChallenges = createAction("[Challenges] Get Challenges");
export const getChallengesSuccess = createAction(
  "[Challenges] Get Challenges Success",
  props<{ challenges: GroupedChallenges | null }>(),
);
export const getChallengesError = createAction(
  "[Challenges] Get Challenges Error",
  props<{ error: string }>(),
);

export const getActiveChallenges = createAction("[Challenges] Get Active Challenges");

export const getActiveChallengesSuccess = createAction(
  "[Challenges] Get Active Challenges Success",
  props<{ challenges: GroupedChallenges | null }>(),
);

export const getActiveChallengesError = createAction(
  "[Challenges] Get Active Challenges Error",
  props<{ error: string }>(),
);

export const getNewChallenge = createAction("[Challenges] Get New Challenge");

export const setNewChallenge = createAction(
  "[Challenges] Set New Challenge",
  props<{ challenge: NewChallenge }>(),
);

export const deleteNewChallenge = createAction("[Challenges] Delete New Challenge");
