import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, switchMap, take } from "rxjs";
import { AuthService } from "@services";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.getIdToken().pipe(
      take(1),
      switchMap((token) => {
        if (token) {
          request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
          request = request.clone({ url: request.url.startsWith("http") ? request.url : "" });
        }

        return next.handle(request);
      }),
    );
  }
}
