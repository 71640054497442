import { Component, NgZone } from "@angular/core";
import { trigger, state, style, animate, transition } from "@angular/animations";
import { NgClass } from "@angular/common";
import { RouterLink } from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";
import { socialLink } from "@constants";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  animations: [
    trigger("expandCollapseAnimation", [
      state("expanded", style({ height: "*", opacity: 1 })),
      state("collapsed", style({ height: "0", opacity: 0 })),
      transition("expanded <=> collapsed", animate("300ms ease-in-out")),
      // Add a default state
      state("*", style({ height: "0", opacity: 0 })),
      transition("void => *", animate("0ms")), // Handle initial state
    ]),
  ],
  standalone: true,
  imports: [RouterLink, NgClass, TranslocoModule],
})
export class FooterComponent {
  social = socialLink;
  textVisible = false;

  constructor(private ngZone: NgZone) {}

  additionalText() {
    this.textVisible = !this.textVisible;

    if (this.textVisible) {
      this.ngZone.runOutsideAngular(() => {
        const element = document.getElementById("targetElement");
        if (element) {
          setTimeout(function () {
            element.scrollIntoView({ behavior: "smooth" });
          }, 350);
        }
      });
    }
  }
}
