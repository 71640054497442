import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env";
import type { Country } from "@models";
import type { LegacyResponse } from "./response.model";

@Injectable({ providedIn: "root" })
export class CommonService {
  private usersApiUrl = environment.usersApiUrl;

  constructor(private httpClient: HttpClient) {}

  getCountries() {
    return this.httpClient.get<LegacyResponse<Country[]>>(`${this.usersApiUrl}/countries`);
  }
}
