import { Injectable } from "@angular/core";
import { HashMap, TranslocoService, type TranslocoEvents } from "@ngneat/transloco";
import { Observable } from "rxjs";
import { LocalStorageService } from "./local-storage.service";
import type { Language } from "src/app/transloco.config";

@Injectable({ providedIn: "root" })
export class TranslateService {
  constructor(
    private translocoService: TranslocoService,
    private localStorageService: LocalStorageService,
  ) {}

  setLanguage(lang: Language): void {
    this.translocoService.setActiveLang(lang);
    this.localStorageService.set("language", lang);
  }

  getTranslationObject(key: string, params?: HashMap, lang?: Language) {
    return this.translocoService.translateObject(key, params, lang);
  }

  selectTranslationObject(key: string, params?: HashMap, lang?: Language): Observable<HashMap> {
    return this.translocoService.selectTranslateObject(key, params, lang);
  }

  getTranslation(key: string, params?: HashMap, lang?: string): string {
    return this.translocoService.translate(key, params, lang);
  }

  getSelectedLanguage(): Language {
    return (this.localStorageService.get("language") as Language) ?? "en";
  }

  events$(): Observable<TranslocoEvents> {
    return this.translocoService.events$;
  }
}
