import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { CookieService as NgxCookieService } from "ngx-cookie-service";

@Injectable({ providedIn: "root" })
export class CookieService {
  private valueSubject: Subject<string> = new Subject<string>();

  constructor(private cookieService: NgxCookieService) {}

  set(key: string, newValue: string, expiresIn?: number, path?: string): void {
    this.cookieService.set(key, newValue, expiresIn, path);
    this.valueSubject.next(newValue);
  }

  get(key: string): string | null {
    return this.cookieService.get(key) ?? null;
  }

  observeValue(): Observable<string> {
    return this.valueSubject.asObservable();
  }
}
