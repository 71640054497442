import { Component } from "@angular/core";
import { TranslocoModule } from "@ngneat/transloco";

@Component({
  selector: "app-section-overlay",
  templateUrl: "./section-overlay.component.html",
  styleUrls: ["./section-overlay.component.scss"],
  standalone: true,
  imports: [TranslocoModule],
})
export class SectionOverlayComponent {}
