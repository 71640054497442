import { Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";

import { filter } from "rxjs/operators";

export interface Breadcrumb {
  label: string;
  route: string;
}

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
  standalone: true,
  imports: [],
})
export class BreadcrumbComponent {
  breadcrumbs: Breadcrumb[] = [];
  queryParams: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    activatedRoute.queryParams.subscribe((query) => {
      if (query.affiliateId) {
        this.queryParams = query;
      }
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    });
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url = "",
    breadcrumbs: Breadcrumb[] = [],
  ): Breadcrumb[] {
    const children = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL = child.snapshot.url.map((segment) => segment.path).join("/");

      if (routeURL !== "") {
        url += `/${routeURL}`;
      }

      if (child.snapshot.data && child.snapshot.data.breadcrumb) {
        const breadcrumbLabel = this.extractBreadcrumbLabel(
          child.snapshot.data.breadcrumb,
          child.snapshot.params,
        );
        breadcrumbs.push({ label: breadcrumbLabel, route: url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }

  private extractBreadcrumbLabel(breadcrumbLabel: string, params: Params): string {
    return breadcrumbLabel.replace(":detail", params.detail);
  }

  navigateToUrl(url: string) {
    if (this.queryParams?.affiliateId) {
      this.router.navigate([url], { queryParams: this.queryParams });
    } else {
      this.router.navigate([url]);
    }
  }
}
