<div>
  @for (breadcrumb of breadcrumbs; track breadcrumb; let last = $last) {
    @if (!last) {
      <a href="javascript:;" (click)="navigateToUrl(breadcrumb.route)">{{ breadcrumb.label }}</a>
      <img src="assets/svg/icon-chevron.svg" alt="" />
    }
    @if (last) {
      {{ breadcrumb.label }}
    }
  }
</div>
