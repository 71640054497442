import { Injectable } from "@angular/core";
import { Store as NgrxStore } from "@ngrx/store";
import type { UserState } from "./user";
import type { ChallengesState } from "./challenges";
import type { CountriesState } from "./countries";

export interface AppState {
  user: UserState;
  challenges: ChallengesState;
  countries: CountriesState;
}

@Injectable({ providedIn: "root" })
export class Store extends NgrxStore<AppState> {}
