import { Pipe, PipeTransform } from "@angular/core";
import type { User } from "@models";

@Pipe({
  name: "userCredentials",
  standalone: true,
})
export class UserCredentialsPipe implements PipeTransform {
  transform(user: User, short = false): string {
    const { userName, firstName, lastName } = user;

    if (!userName) {
      if (short) return firstName.charAt(0);

      return `${firstName} ${lastName}`;
    }

    if (short) return userName.substring(0, 1);

    return userName;
  }
}
