import { Component, Input } from "@angular/core";

import { RouterLink, RouterLinkActive } from "@angular/router";

export interface NavigationTab {
  label: string;
  link: string;
}

@Component({
  selector: "app-navigation-tabs",
  templateUrl: "./navigation-tabs.component.html",
  styleUrls: ["./navigation-tabs.component.scss"],
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
})
export class NavigationTabsComponent {
  @Input() tabs: NavigationTab[];
}
