import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatProgressSpinnerModule],
})
export class ButtonComponent {
  @Input() htmlType: HTMLButtonElement["type"] = "button";
  @Input() ariaLabel: string;
  @Input() size: "x-small" | "small" | "medium" | "table-small" | "large" = "small";
  @Input() type: "primary" | "secondary" | "tertiary-table" | "tertiary" = "primary";
  @Input() disabled = false;
  @Input() loading = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter<void>();
  @Output() submitEvent = new EventEmitter<void>();

  handleOnClick() {
    if (this.disabled || this.loading) return;

    this.onClick.emit();
  }

  handleSubmit(): void {
    if (this.disabled || this.loading) return;

    this.submitEvent.emit();
  }
}
