import { createAction, props } from "@ngrx/store";
import type { User } from "@models";

export const getUser = createAction("[User] Get User");
export const getUserSuccess = createAction("[User] Get User Success", props<{ user: User }>());
export const getUserError = createAction("[User] Get User Error", props<{ error: string }>());

export const clearCurrentUser = createAction("[User] Clear Current User");
export const setCurrentUser = createAction("[User] Set Current User", props<{ user: User }>());

export const getUserTokens = createAction("[User] Get User Tokens");
export const getUserTokensSuccess = createAction(
  "[User] Get User Tokens Success",
  props<{ tokens: number }>(),
);
export const getUserTokensError = createAction(
  "[User] Get User Tokens Error",
  props<{ error: string }>(),
);
