<div class="social-icons">
  @switch (combination) {
    @case ("a") {
      @for (icon of allIcons; track icon.link) {
        <app-social-icon [icon]="icon.icon" [link]="icon.link" [type]="type" />
      }
    }
    @case ("b") {
      @for (icon of contactIcons; track icon.link) {
        <app-social-icon [icon]="icon.icon" [link]="icon.link" [type]="type" />
      }
    }
    @case ("c") {
      @for (icon of homepageIcons; track icon.link) {
        <app-social-icon [icon]="icon.icon" [link]="icon.link" [type]="type" />
      }
    }
  }
</div>
