import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatSlideToggleChange, MatSlideToggleModule } from "@angular/material/slide-toggle";

@Component({
  selector: "app-toggle",
  standalone: true,
  imports: [MatSlideToggleModule],
  templateUrl: "./toggle.component.html",
  styleUrl: "./toggle.component.scss",
})
export class ToggleComponent {
  @Input() label: string;
  @Input() checked = false;

  @Output() toggleChange = new EventEmitter<MatSlideToggleChange>();

  onToggleChange(event: MatSlideToggleChange) {
    this.toggleChange.emit(event);
  }
}
