export const environment = {
  production: false,

  usersApiUrl: "https://api.dev-3.brightfunded2.com/users/2",
  challengesApiUrl: "https://api.dev-3.brightfunded2.com/challenges/1",
  vouchersApiUrl: "https://api.dev-3.brightfunded2.com/vouchers/1",
  vouchersV2ApiUrl: "https://api.dev-3.brightfunded2.com/vouchers/2",
  statisticsApiUrl: "https://api.dev-3.brightfunded2.com/statistics/1",

  isInspect: true,
  title: "BrightFunded Dev",

  firebaseConfig: {
    apiKey: "AIzaSyBjwDFODg2TAnAER9dgZwqid2atqp5DtYg",
    authDomain: "brightfunded-dev-3.firebaseapp.com",
    projectId: "brightfunded-dev-3",
    storageBucket: "brightfunded-dev-3.appspot.com",
    messagingSenderId: "195781189138",
    appId: "1:195781189138:web:9cf0f1f5a784cba09d82cb",
  },
  googleTagManagerId: "GTM-WMH5WVKB",
  intercomAppId: "ivhgfmtk",
  recaptchaSiteKey: "6Lf3wSonAAAAAF_vy7Hg1-UJLDHMcmfGxLuSxJuS",
};
