import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonComponent } from "../button/button.component";

@Component({
  selector: "app-empty",
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: "./empty.component.html",
  styleUrl: "./empty.component.scss",
})
export class EmptyComponent {
  @Input({ required: true }) title: string;
  @Input() description: string;
  @Input() buttonLabel: string;
  @Input() showImage = false;

  @Output() buttonAction = new EventEmitter<void>();

  onButtonAction() {
    this.buttonAction.emit();
  }
}
