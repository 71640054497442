import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable, map } from "rxjs";
import { take } from "rxjs/operators";
import { AuthService } from "@services";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { Store, selectCurrentUser } from "@store";

@Injectable({ providedIn: "root" })
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private gtmService: GoogleTagManagerService,
    private store: Store,
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.router.navigate(["/auth/login"], { queryParams: next.queryParams });
          return false;
        }

        this.store
          .select(selectCurrentUser)
          .pipe(take(2))
          .subscribe((user) => {
            if (user?.id) {
              const gtmTag = {
                event: "login",
                user: {
                  id: user?.id,
                  email: user?.userEmail,
                  country: user.countryCode,
                },
              };
              this.gtmService.pushTag(gtmTag);
            }
          });

        return true;
      }),
    );
  }
}
