import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { PaginationButtonComponent } from "./pagination-button/pagination-button.component";

@Component({
  selector: "app-pagination",
  standalone: true,
  imports: [PaginationButtonComponent],
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  @Input() totalPages: number;

  @Output() pageChange = new EventEmitter<number>();

  MAX_PAGES = 3;
  currentPage = 1;
  pages: number[] = [];
  leftPages: number[] = [];
  rightPages: number[] = [];

  ngOnInit(): void {
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);

    this.leftPages = [...new Set(this.pages.slice(0, this.MAX_PAGES))];
    this.rightPages =
      this.totalPages > this.MAX_PAGES
        ? this.pages.slice(this.totalPages - 1, this.totalPages)
        : [];
  }

  setPageNumbers(currentPage: number) {
    const totalPages = this.totalPages;
    const maxPages = this.MAX_PAGES;

    if (totalPages <= maxPages) {
      this.leftPages = this.pages.slice(0, totalPages);
      this.rightPages = [];
      return;
    }

    if (currentPage === 0) {
      this.leftPages = this.pages.slice(0, maxPages);
      this.rightPages = this.pages.slice(totalPages - 1, totalPages);
    }

    if (currentPage === totalPages) {
      this.rightPages = this.pages.slice(totalPages - maxPages, totalPages);
      this.leftPages = this.pages.slice(0, 1);
    }

    if (this.leftPages.at(0) === currentPage && currentPage !== 1) {
      this.leftPages = this.pages.slice(currentPage - 2, currentPage + 1);
    }

    if (this.leftPages.at(-1) === currentPage && currentPage !== 1) {
      this.leftPages = this.pages.slice(currentPage - 2, currentPage + 1);
    }

    if (this.leftPages.at(-1) === totalPages) {
      this.leftPages = this.pages.slice(0, 1);
      this.rightPages = this.pages.slice(currentPage - 2, currentPage + 1);
    }

    if (this.rightPages.at(-1) === currentPage && currentPage !== maxPages) {
      this.rightPages = this.pages.slice(currentPage - 2, currentPage + 1);
    }

    if (this.rightPages.at(0) === currentPage) {
      this.rightPages = this.pages.slice(currentPage - 2, currentPage + 1);
    }

    if (this.rightPages.at(1) === 2) {
      this.leftPages = this.pages.slice(0, maxPages);
      this.rightPages = this.pages.slice(totalPages - 1, totalPages);
    }

    if (this.rightPages.at(-1) === currentPage) {
      this.rightPages = this.pages.slice(currentPage - 3, currentPage + 1);
    }

    if (currentPage === 1 && this.rightPages.length === 3) {
      this.rightPages = this.pages.slice(totalPages - 1, totalPages);
      this.leftPages = this.pages.slice(0, 3);
    }
  }

  handlePageChange(selectedPage: number) {
    this.currentPage = selectedPage;
    this.setPageNumbers(selectedPage);
    this.pageChange.emit(selectedPage);
  }
}
