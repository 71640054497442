<div class="landing">
  <div class="starWrapper">
    <canvas #canvas width="1920" height="979" style="width: 100%; height: 100%"></canvas>
  </div>
  <app-header></app-header>
  @if (!isCookiesAccepted) {
    <div class="CookiesModal_cookies">
      <div class="CookiesModal_cookies__text">
        We use<a href="javascript:;" (click)="navigateToUrl('cookies')">cookies</a>on this website
        to optimize your online experience.
      </div>
      <button class="CookiesModal_cookies__btn" (click)="acceptCookie()">Accept</button>
    </div>
  }
  <main class="main">
    <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer>
</div>
