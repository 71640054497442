import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-visibility-button",
  standalone: true,
  templateUrl: "./visibility-button.component.html",
  styleUrl: "./visibility-button.component.scss",
})
export class VisibilityButtonComponent {
  @Output() toggle = new EventEmitter<boolean>();

  isVisible = false;

  toggleVisibility() {
    this.isVisible = !this.isVisible;
    this.toggle.emit(this.isVisible);
  }
}
