import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env";
import type { LegacyResponse } from "./response.model";
import type { Planet, UserVoucher, Voucher, VoucherType } from "@models";

@Injectable({ providedIn: "root" })
export class VoucherService {
  private vouchersApiUrl = environment.vouchersApiUrl;
  private voucherApiUrlV2 = environment.vouchersV2ApiUrl;

  constructor(private httpClient: HttpClient) {}

  getVouchers() {
    return this.httpClient.get<LegacyResponse<UserVoucher[]>>(`${this.vouchersApiUrl}/mine`);
  }

  getUserVouchers(voucherType?: VoucherType) {
    return this.httpClient.get<{ vouchers: UserVoucher[] }>(`${this.voucherApiUrlV2}/mine`, {
      params: voucherType ? { voucherType } : {},
    });
  }

  getPurchasableVouchers(planet?: Planet) {
    return this.httpClient.get<{ products: Voucher[] }>(
      `${this.voucherApiUrlV2}/earn/available-products`,
      {
        params: planet ? { planet } : {},
      },
    );
  }

  getVoucherByCode(code: string) {
    return this.httpClient.get<UserVoucher>(`${this.voucherApiUrlV2}/search`, {
      params: { voucherCode: code },
    });
  }

  getUserAvailableTokens() {
    return this.httpClient.get<{ amount: number }>(`${this.voucherApiUrlV2}/earn/tokens`);
  }

  purchaseVouchers(voucherIds: string[]) {
    return this.httpClient.post<{ vouchers: UserVoucher[] }>(
      `${this.voucherApiUrlV2}/earn/purchase`,
      {
        productIds: voucherIds,
      },
    );
  }
}
