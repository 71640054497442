import { createReducer, on } from "@ngrx/store";
import { getCountries, getCountriesError, getCountriesSuccess } from "./countries.actions";
import type { Country, Nullable } from "@models";

export interface CountriesState {
  countries: Nullable<Country[]>;
  error: Nullable<string>;
  isLoading: boolean;
}

const initialState: CountriesState = {
  countries: null,
  isLoading: false,
  error: null,
};

export const countriesReducer = createReducer(
  initialState,
  on(getCountries, (state) => ({ ...state, isLoading: true })),
  on(getCountriesSuccess, (state, { countries }) => ({
    ...state,
    countries,
    isLoading: false,
    error: null,
  })),
  on(getCountriesError, (state, { error }) => ({ ...state, error, isLoading: false })),
);
