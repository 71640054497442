<div class="dialog">
  <button class="dialog__close-button" [mat-dialog-close]="validatedData">
    <img src="assets/svg/icon-close.svg" alt="close" />
  </button>
  <h2 class="dialog__heading">{{ data.title }}</h2>
  @if (data.subtitle) {
    <p class="dialog__description">{{ data.subtitle }}</p>
  }
  <ng-container *ngComponentOutlet="data.component"></ng-container>
</div>
