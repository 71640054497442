import { createSelector } from "@ngrx/store";
import type { AppState } from "@store";

const selectChallengesState = (state: AppState) => state.challenges;

export const selectChallenges = createSelector(selectChallengesState, (state) => state.challenges);
export const selectChallengesLoading = createSelector(
  selectChallengesState,
  (state) => state.isLoading,
);
export const selectChallengesError = createSelector(selectChallengesState, (state) => state.error);

export const selectActiveChallenges = createSelector(
  selectChallengesState,
  (state) => state.activeChallenges,
);

export const selectNewChallenge = createSelector(
  selectChallengesState,
  (state) => state.newChallenge,
);
