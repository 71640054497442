<div class="empty">
  @if (showImage) {
    <img src="assets/images/empty.svg" alt="Empty" class="empty__image" />
  }
  <h3 class="empty__title">{{ title }}</h3>
  @if (description) {
    <p class="empty__description">{{ description }}</p>
  }
  @if (buttonLabel) {
    <app-button (onClick)="onButtonAction()">{{ buttonLabel }}</app-button>
  }
</div>
