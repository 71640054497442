import { createReducer, on } from "@ngrx/store";
import type { Nullable, User } from "@models";
import {
  clearCurrentUser,
  getUser,
  getUserError,
  getUserSuccess,
  getUserTokens,
  getUserTokensError,
  getUserTokensSuccess,
  setCurrentUser,
} from "./user.actions";

export interface UserState {
  isAuthenticated: boolean;
  userInfo: Nullable<User>;
  tokens: number;
}

const initialState: UserState = { isAuthenticated: false, userInfo: null, tokens: 0 };

export const userReducer = createReducer(
  initialState,
  on(getUser, (state) => ({ ...state, userInfo: null, isAuthenticated: false })),
  on(getUserSuccess, (state, { user }) => ({ ...state, userInfo: user, isAuthenticated: true })),
  on(getUserError, (state) => ({ ...state, userInfo: null, isAuthenticated: false })),

  on(setCurrentUser, (state, { user }) => ({ ...state, userInfo: user, isAuthenticated: true })),
  on(clearCurrentUser, () => initialState),

  on(getUserTokens, (state) => ({ ...state })),
  on(getUserTokensSuccess, (state, { tokens }) => ({ ...state, tokens })),
  on(getUserTokensError, (state) => ({ ...state, tokens: 0 })),
);
