<button
  class="button"
  [attr.aria-label]="ariaLabel"
  [type]="htmlType"
  [disabled]="disabled"
  [ngClass]="['button--' + size, 'button--' + type]"
  (click)="handleOnClick()"
>
  <ng-content></ng-content>
  @if (loading) {
    <span class="button__spinner">
      <mat-spinner diameter="25"></mat-spinner>
    </span>
  }
</button>
