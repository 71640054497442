import { TranslateService } from "@services";
import type { AddOnAction, ChallengeAddOn } from "@models";

function getAddOnAction(addonAction: AddOnAction, translateService: TranslateService): string {
  const { type, ratio } = addonAction;

  const action = translateService.getTranslation(
    `app.dashboard.addOn.action.${ratio === 0 ? type + "_0" : type + `_${ratio}`}`,
  );

  const actionRatio = ratio === 0 ? "" : `${ratio * 100}%`;

  return `${type === "PAYOUTS_FREQUENCY_INCREASE" ? "" : actionRatio} ${action}`;
}

export function getAddOnTitle(addOn: ChallengeAddOn, translateService: TranslateService): string {
  const suffix = translateService.getTranslation("app.dashboard.addOn.suffix");
  const fee = 100 * addOn.feeIncreaseRatio;

  const feeInfo = `(${suffix} +${fee}%)`;

  const actions = addOn.addonActions
    .map((action) => getAddOnAction(action, translateService))
    .join(", ");

  return `${actions} ${feeInfo}`;
}
