<!-- <div class="d-flex flex-column flex-root h-100">
    <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-fill bg-white" id="kt_login">
        <div
            class="flex-fill d-flex flex-column justify-content-center align-items-center position-relative p-4 overflow-hidden">
            <div class="position-absolute top-0 left-0 flex-column-auto justify-content-center logo">
                <img src="assets/images/logo.png" class="max-h-70px" alt="" />
            </div>
            <div class="d-flex flex-column-fluid mt-30 mt-lg-0">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="login-aside d-none d-lg-flex flex-row-auto jusify-content-center bgi-size-cover bgi-no-repeat p-5"
            style="background-image: url(assets/images/bg-4.jpg);">
            <div class="d-flex flex-row-fluid flex-column justify-content-between">
                <a href="#" class="flex-column-auto mt-5">

                </a>
                <div class="flex-column-fluid d-flex flex-column justify-content-center">
                    <h3 class="font-size-h1 mb-5 text-white">Bright Funded</h3>
                    <p class="font-weight-lighter text-white opacity-80"></p>
                </div>
                <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                    <div class="opacity-70 font-weight-bold text-white">© 2024 Bright Funded</div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="starWrapper">
  <canvas #stars width="1920" height="979" style="width: 100%; height: 100%"></canvas>
</div>
<div class="position-relative h-100">
  <router-outlet></router-outlet>
</div>
