import { createReducer, on } from "@ngrx/store";
import type { GroupedChallenges, NewChallenge, Nullable } from "@models";
import {
  getChallenges,
  getChallengesSuccess,
  getChallengesError,
  getNewChallenge,
  setNewChallenge,
  deleteNewChallenge,
  getActiveChallenges,
  getActiveChallengesSuccess,
} from "./challenges.actions";

export interface ChallengesState {
  challenges: Nullable<GroupedChallenges>;
  activeChallenges: Nullable<GroupedChallenges>;
  newChallenge: Nullable<NewChallenge>;
  error: Nullable<string>;
  isLoading: boolean;
}

const initialState: ChallengesState = {
  challenges: null,
  activeChallenges: null,
  newChallenge: null,
  isLoading: false,
  error: null,
};

export const challengesReducer = createReducer(
  initialState,
  on(getChallenges, (state) => ({ ...state, isLoading: true })),
  on(getChallengesSuccess, (state, { challenges }) => ({
    ...state,
    challenges,
    isLoading: false,
    error: null,
  })),
  on(getChallengesError, (state, { error }) => ({ ...state, error, isLoading: false })),

  on(getActiveChallenges, (state) => ({ ...state, isLoading: true })),
  on(getActiveChallengesSuccess, (state, { challenges }) => ({
    ...state,
    activeChallenges: challenges,
    isLoading: false,
    error: null,
  })),
  on(getChallengesError, (state, { error }) => ({ ...state, error, isLoading: false })),

  on(getNewChallenge, (state) => ({ ...state, isLoading: true })),
  on(setNewChallenge, (state, { challenge }) => ({
    ...state,
    isLoading: false,
    newChallenge: challenge,
  })),
  on(deleteNewChallenge, (state) => ({ ...state, newChallenge: null })),
);
