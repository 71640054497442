import { createSelector } from "@ngrx/store";
import type { AppState } from "./../../store/store";

const selectChallengesState = (state: AppState) => state.countries;

export const selectCountries = createSelector(selectChallengesState, (state) => state.countries);
export const selectCountriesLoading = createSelector(
  selectChallengesState,
  (state) => state.isLoading,
);
export const selectCountriesError = createSelector(selectChallengesState, (state) => state.error);
