import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@services";
import type { ChallengeType } from "@models";

@Component({
  selector: "app-challenge-account-type",
  standalone: true,
  templateUrl: "./challenge-account-type.component.html",
  styleUrls: ["./challenge-account-type.component.scss"],
})
export class ChallengeAccountTypeComponent implements OnInit {
  @Input({ required: true }) chargeSwapFees = false;

  accountType: ChallengeType;
  label = "";

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.accountType = this.chargeSwapFees ? "SWAP" : "SWAP_FREE";
    this.label = this.translateService.getTranslation(`shared.accountType.${this.accountType}`);
  }
}
