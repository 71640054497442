<div class="pagination">
  @for (page of leftPages; track page) {
    <app-pagination-button
      [page]="page"
      [selected]="page === currentPage"
      (pageChange)="handlePageChange(page)"
    />
  }
  @if (pages.length > MAX_PAGES + 1) {
    <span class="pagination__dots">...</span>
  }
  @for (page of rightPages; track page) {
    <app-pagination-button
      [page]="page"
      [selected]="page === currentPage"
      (pageChange)="handlePageChange(page)"
    />
  }
</div>
