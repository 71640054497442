import { createAction, props } from "@ngrx/store";
import type { Country } from "@models";

export const getCountries = createAction("[Countries] Get Countries");
export const getCountriesSuccess = createAction(
  "[Countries] Get Countries Success",
  props<{ countries: Country[] | null }>(),
);
export const getCountriesError = createAction(
  "[Countries] Get Countries Error",
  props<{ error: string }>(),
);
