import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatRadioChange, MatRadioModule } from "@angular/material/radio";
import { FormsModule } from "@angular/forms";

import { InputRadioComponent } from "../input-radio.component";

interface InputRadio {
  label: string;
  value: string;
  selected: boolean;
}

@Component({
  selector: "app-input-radio-group",
  standalone: true,
  imports: [FormsModule, MatRadioModule, InputRadioComponent],
  templateUrl: "./input-radio-group.component.html",
  styleUrls: ["./input-radio-group.component.scss"],
})
export class InputRadioGroupComponent implements OnInit {
  @Input() options: InputRadio[] = [];

  @Output() handleSelect = new EventEmitter<MatRadioChange>();

  selected = "";

  handleOnChange(event: MatRadioChange) {
    this.handleSelect.emit(event.value);
    this.selected = event.value;
  }

  ngOnInit() {
    this.selected = this.options.find((option) => option.selected)?.value || "";
  }
}
