import { Component, Input } from "@angular/core";
import { MatRadioModule } from "@angular/material/radio";

@Component({
  selector: "app-input-radio",
  standalone: true,
  imports: [MatRadioModule],
  templateUrl: "./input-radio.component.html",
  styleUrls: ["./input-radio.component.scss"],
})
export class InputRadioComponent {
  @Input({ required: true }) label: string;
  @Input({ required: true }) value: string;
  @Input() selected = false;
}
