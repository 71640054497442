import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { APP_INITIALIZER, importProvidersFrom } from "@angular/core";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { AuthModule, getAuth, provideAuth } from "@angular/fire/auth";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations, provideNoopAnimations } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import {
  TRANSLOCO_LOADER,
  TranslocoService,
  provideTransloco,
  translocoConfig,
} from "@ngneat/transloco";
import { provideEffects } from "@ngrx/effects";
import { provideStore } from "@ngrx/store";
import { IntercomModule } from "@supy-io/ngx-intercom";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import { ToastrModule } from "ngx-toastr";
import { register } from "swiper/element/bundle";

import { environment } from "@env";
import {
  ChallengesEffects,
  CountriesEffects,
  UserEffects,
  challengesReducer,
  countriesReducer,
  userReducer,
} from "@store";
import { AppComponent } from "./app/app.component";
import { routes } from "./app/app.routes";
import { ApiInterceptor, ErrorInterceptor } from "./app/core/interceptor";
import { TRANSLOCO_CONFIG, TranslocoHttpLoader, loadTranslations } from "./app/transloco.config";

if (environment.production) {
  const script = document.createElement("script");
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-WMH5WVKB');`;
  document.head.appendChild(script);
} else {
  const script = document.createElement("script");
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=7Wg0Lt2c5DjOsryuaYZMQg&gtm_preview=env-94&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-WMH5WVKB')`;
  document.head.appendChild(script);
}

register();

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      RecaptchaModule,
      RecaptchaFormsModule,
      BrowserModule,
      ReactiveFormsModule,
      RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
      ToastrModule.forRoot(),
      GoogleTagManagerModule.forRoot({ id: environment.googleTagManagerId }),
      IntercomModule.forRoot({ appId: environment.intercomAppId, updateOnRouterChange: true }),
      AuthModule,
      provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
      provideAuth(() => getAuth()),
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore({ user: userReducer, challenges: challengesReducer, countries: countriesReducer }),
    provideEffects(ChallengesEffects, UserEffects, CountriesEffects),
    provideTransloco({ config: translocoConfig(TRANSLOCO_CONFIG) }),
    provideNoopAnimations(),
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslocoService],
      useFactory: loadTranslations,
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptchaSiteKey,
      },
    },
  ],
  // eslint-disable-next-line no-console
}).catch((err) => console.error(err));
