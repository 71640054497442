import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { NgClass, CommonModule } from "@angular/common";

export type BadgeStatus = "plain" | "info" | "success" | "warning" | "danger";

@Component({
  selector: "app-status-badge",
  templateUrl: "./status-badge.component.html",
  styleUrls: ["./status-badge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, CommonModule],
})
export class StatusBadgeComponent implements OnInit {
  @Input({ required: true }) label: string;
  @Input({ required: true }) status: BadgeStatus = "info";
  @Input() size: "medium" | "large" = "large";
  @Input() iconPath?: string;
  @Input() iconType?: string;

  ngOnInit(): void {
    if (this.iconType && !this.iconPath) {
      this.setIconPath();
    }
  }

  private setIconPath(): void {
    this.iconPath = `assets/svg/badge-icon-${this.iconType}.svg`;
  }
}
