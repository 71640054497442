import { Component, Input } from "@angular/core";

export enum TableBadgeStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
  EMPTY = "EMPTY",
}

@Component({
  selector: "app-table-status-badge",
  templateUrl: "./table-status-badge.component.html",
  styleUrls: ["./table-status-badge.component.scss"],
  standalone: true,
})
export class TableStatusBadgeComponent {
  @Input({ required: true }) status: TableBadgeStatus = TableBadgeStatus.SUCCESS;
  @Input({ required: true }) label = "";
}
