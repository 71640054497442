<a
  class="social-icon"
  [ngClass]="['social-icon--' + type]"
  [href]="link"
  target="_blank"
  [attr.aria-label]="icon"
  (mouseover)="handleHover()"
  (mouseout)="handleLeave()"
>
  <img [src]="imageSrc()" alt="" />
</a>
