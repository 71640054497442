import { Component, Input } from "@angular/core";
import { MatProgressSpinnerModule, ProgressSpinnerMode } from "@angular/material/progress-spinner";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
  standalone: true,
  imports: [MatProgressSpinnerModule],
})
export class SpinnerComponent {
  @Input() mode: ProgressSpinnerMode = "indeterminate";
  @Input() diameter = 75;
}
