import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-pagination-button",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./pagination-button.component.html",
  styleUrls: ["./pagination-button.component.scss"],
})
export class PaginationButtonComponent {
  @Input({ required: true }) page: number;
  @Input() selected = false;

  @Output() pageChange = new EventEmitter<number>();

  onClick() {
    this.pageChange.emit(this.page);
  }
}
