import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-token-ballance",
  templateUrl: "./token-ballance.component.html",
  styleUrls: ["./token-ballance.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class TokenBallanceComponent {
  @Input() availableTokens: number;
  @Input() size: "small" | "large" = "small";
}
