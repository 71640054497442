import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, catchError } from "rxjs";
import { TranslateService } from "@services";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  queryParams: Params;

  constructor(
    private router: Router,
    private toastService: ToastrService,
    private translateService: TranslateService,
    route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((query) => {
      if (query.affiliateId) {
        this.queryParams = query;
      }
    });
  }

  private handleError(response: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    if (response?.url?.endsWith("users/1/me")) {
      throw response;
    }

    const errorCode = response?.error?.errorCode;

    this.toastService.error(
      this.translateService.getTranslation(
        errorCode ? `error.${errorCode}` : "error.client.generic",
      ),
    );

    throw response;
  }

  private handleSessionExpiredError(): void {
    this.toastService.error(this.translateService.getTranslation("error.client.sessionExpired"));
    this.router.navigate(["/auth/login"], { queryParams: this.queryParams });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.endsWith("users/1/login")) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error.status === HttpStatusCode.Forbidden &&
          !request.url.endsWith("users/1/add-authenticated")
        ) {
          this.handleSessionExpiredError();
          throw error;
        }

        return this.handleError(error);
      }),
    );
  }
}
