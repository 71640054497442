import { Component, Input } from "@angular/core";

@Component({
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
  standalone: true,
})
export class PageHeaderComponent {
  @Input() heading: string;
  @Input() direction: "row" | "column" | "row-reverse" | "column-reverse" = "row";
  @Input() justify: "start" | "center" | "space-between" = "space-between";
}
