import { Component, Input } from "@angular/core";
import { NgbTooltip, NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-info-tooltip",
  standalone: true,
  imports: [NgbTooltip],
  templateUrl: "./info-tooltip.component.html",
  styleUrl: "./info-tooltip.component.scss",
})
export class InfoTooltipComponent {
  @Input() position: NgbTooltipConfig["placement"] = "auto";

  isTooltipVisible = false;

  handleTooltipVisibilityChange(isVisible: boolean) {
    this.isTooltipVisible = isVisible;
  }
}
