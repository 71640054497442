import { Injectable, EventEmitter, Output } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  @Output() sidebar = new EventEmitter<string>();

  showSidenav() {
    this.sidebar.emit();
  }
}
