import { Injectable } from "@angular/core";
import { AnalyticsService } from "../analytics.service";
import { TrackingEvent } from "./util";
import { UserInfo } from "./tracking.model";

@Injectable({ providedIn: "root" })
export class AuthTrackingService {
  constructor(private analyticsService: AnalyticsService) {}

  trackUserRegistration(data: UserInfo) {
    this.analyticsService.trackEvent(TrackingEvent.SIGNUP, data);
  }
}
