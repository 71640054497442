import { Routes } from "@angular/router";
import { AuthGuard } from "./core/guard/auth.guard";
import { MainComponent } from "./landing/shared/layout/main/main.component";
import { AuthLayoutComponent } from "./shared/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./shared/app-layout/main-layout/main-layout.component";
import { LanguageResolver } from "./core/resolvers/language-resolver";

export const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    loadChildren: () => import("./landing/landing.routes").then((r) => r.landingRoutes),
  },
  // INFO: Default route for English without language prefix
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        children: [
          {
            path: "",
            data: { breadcrumb: "Dashboard" },
            loadChildren: () =>
              import("./dashboard/dashboard.routes").then((r) => r.dashboardRoutes),
          },
        ],
      },
      {
        path: "accounts",
        data: { breadcrumb: "Challenges" },
        loadComponent: () =>
          import("./dashboard/all-challenges/all-challenges.component").then(
            (c) => c.AllChallengesComponent,
          ),
      },
      {
        path: "payouts",
        children: [
          {
            path: "",
            data: { breadcrumb: "Payouts" },
            loadChildren: () => import("./payouts/payouts.routes").then((r) => r.payoutsRoutes),
          },
        ],
      },
      {
        path: "contracts",
        children: [
          {
            path: "",
            data: { breadcrumb: "Contracts" },
            loadChildren: () =>
              import("./contracts/contracts.routes").then((r) => r.contractsRoutes),
          },
        ],
      },
      {
        path: "profile",
        children: [
          {
            path: "",
            data: { breadcrumb: "Profile" },
            loadChildren: () => import("./profile/profile.routes").then((r) => r.profileRoutes),
          },
        ],
      },
      {
        path: "trading-academy",
        children: [
          {
            path: "",
            data: { breadcrumb: "Trading Academy" },
            loadChildren: () =>
              import("./trading-academy/trading-academy.routes").then(
                (r) => r.tradingAcademyRoutes,
              ),
          },
        ],
      },
      {
        path: "billing",
        children: [
          {
            path: "",
            data: { breadcrumb: "Billing" },
            loadChildren: () => import("./billing/billing.routes").then((r) => r.billingRoutes),
          },
        ],
      },
      {
        path: "contact-us",
        children: [
          {
            path: "",
            data: { breadcrumb: "Contact us" },
            loadChildren: () =>
              import("./contact-us/contact-us.routes").then((r) => r.contactUsRoutes),
          },
        ],
      },
      {
        path: "download",
        children: [
          {
            path: "",
            data: { breadcrumb: "Download" },
            loadChildren: () =>
              import("./downloads/downloads.routes").then((r) => r.downloadsRoutes),
          },
        ],
      },
      {
        path: "earn",
        children: [
          {
            path: "",
            loadChildren: () => import("./earn/earn.routes").then((r) => r.earnRoutes),
          },
        ],
      },
      {
        path: "payment-status",
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./payment-status/payment-status.routes").then((r) => r.paymentStatusRoutes),
          },
        ],
      },
      {
        path: "customer-support",
        children: [
          {
            path: "",
            data: { breadcrumb: "Customer Support" },
            loadChildren: () =>
              import("./customer-support/customer-support.routes").then(
                (r) => r.customerSupportRoutes,
              ),
          },
        ],
      },
      {
        path: "affiliate",
        children: [
          {
            path: "",
            data: { breadcrumb: "Affiliate Portal" },
            loadChildren: () =>
              import("./affiliate/affiliate.routes").then((r) => r.affiliateRoutes),
          },
        ],
      },
    ],
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./auth/auth.routes").then((r) => r.authRoutes),
      },
    ],
  },
  // INFO: Routes with language prefix
  {
    path: ":lang",
    resolve: { lang: LanguageResolver },
    children: [
      {
        path: "",
        component: MainComponent,
        loadChildren: () => import("./landing/landing.routes").then((r) => r.landingRoutes),
      },
      {
        path: "",
        component: MainLayoutComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "dashboard",
            children: [
              {
                path: "",
                data: { breadcrumb: "Dashboard" },
                loadChildren: () =>
                  import("./dashboard/dashboard.routes").then((r) => r.dashboardRoutes),
              },
            ],
          },
          {
            path: "accounts",
            data: { breadcrumb: "Challenges" },
            loadComponent: () =>
              import("./dashboard/all-challenges/all-challenges.component").then(
                (c) => c.AllChallengesComponent,
              ),
          },
          {
            path: "payouts",
            children: [
              {
                path: "",
                data: { breadcrumb: "Payouts" },
                loadChildren: () => import("./payouts/payouts.routes").then((r) => r.payoutsRoutes),
              },
            ],
          },
          {
            path: "contracts",
            children: [
              {
                path: "",
                data: { breadcrumb: "Contracts" },
                loadChildren: () =>
                  import("./contracts/contracts.routes").then((r) => r.contractsRoutes),
              },
            ],
          },
          {
            path: "profile",
            children: [
              {
                path: "",
                data: { breadcrumb: "Profile" },
                loadChildren: () => import("./profile/profile.routes").then((r) => r.profileRoutes),
              },
            ],
          },
          {
            path: "trading-academy",
            children: [
              {
                path: "",
                data: { breadcrumb: "Trading Academy" },
                loadChildren: () =>
                  import("./trading-academy/trading-academy.routes").then(
                    (r) => r.tradingAcademyRoutes,
                  ),
              },
            ],
          },
          {
            path: "billing",
            children: [
              {
                path: "",
                data: { breadcrumb: "Billing" },
                loadChildren: () => import("./billing/billing.routes").then((r) => r.billingRoutes),
              },
            ],
          },
          {
            path: "contact-us",
            children: [
              {
                path: "",
                data: { breadcrumb: "Contact us" },
                loadChildren: () =>
                  import("./contact-us/contact-us.routes").then((r) => r.contactUsRoutes),
              },
            ],
          },
          {
            path: "download",
            children: [
              {
                path: "",
                data: { breadcrumb: "Download" },
                loadChildren: () =>
                  import("./downloads/downloads.routes").then((r) => r.downloadsRoutes),
              },
            ],
          },
          {
            path: "earn",
            children: [
              {
                path: "",
                loadChildren: () => import("./earn/earn.routes").then((r) => r.earnRoutes),
              },
            ],
          },
          {
            path: "payment-status",
            children: [
              {
                path: "",
                loadChildren: () =>
                  import("./payment-status/payment-status.routes").then(
                    (r) => r.paymentStatusRoutes,
                  ),
              },
            ],
          },
          {
            path: "customer-support",
            children: [
              {
                path: "",
                data: { breadcrumb: "Customer Support" },
                loadChildren: () =>
                  import("./customer-support/customer-support.routes").then(
                    (r) => r.customerSupportRoutes,
                  ),
              },
            ],
          },
          {
            path: "affiliate",
            children: [
              {
                path: "",
                data: { breadcrumb: "Affiliate Portal" },
                loadChildren: () =>
                  import("./affiliate/affiliate.routes").then((r) => r.affiliateRoutes),
              },
            ],
          },
        ],
      },
      {
        path: "auth",
        component: AuthLayoutComponent,
        children: [
          {
            path: "",
            loadChildren: () => import("./auth/auth.routes").then((r) => r.authRoutes),
          },
        ],
      },
    ],
  },
];
