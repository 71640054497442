import { Inject, Injectable, isDevMode } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import Analytics from "analytics";
// There is no type definition available for this package.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import googleAnalytics from "@analytics/google-analytics";
import { TrackingEvent } from "./tracking/util";

@Injectable({ providedIn: "root" })
export class AnalyticsService {
  private analytics = Analytics({
    app: "BrightFunded",
    plugins: [
      googleAnalytics({
        measurementIds: ["G-8CGTMTS8CM"],
        enabled: !isDevMode(),
        debug: location.host.includes("dev-3"),
      }),
    ],
  });

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  trackEvent(eventName: TrackingEvent, data: Record<string, unknown>) {
    this.analytics.track(eventName, data);
  }

  identifyUser(userData: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    postalCode: string;
  }) {
    const { id, email, firstName, lastName, postalCode } = userData;

    this.analytics.identify(id, {
      email,
      firstName,
      lastName,
      postalCode,
    });
  }

  injectActiveCampaignScript() {
    const script = `(function (e, t, o, n, p, r, i) {
      e.visitorGlobalObjectAlias = n;
      e[e.visitorGlobalObjectAlias] =
        e[e.visitorGlobalObjectAlias] ||
        function () {
          (e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(
            arguments,
          );
        };
      e[e.visitorGlobalObjectAlias].l = new Date().getTime();
      r = t.createElement("script");
      r.src = o;
      r.async = true;
      i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
    })(window, document, "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js", "vgo");
    vgo("setAccount", "612990386");
    vgo("setTrackByDefault", true);
    vgo("process");`;

    const scriptTag = document.createElement("script");

    scriptTag.setAttribute("id", "activeCampaign");

    scriptTag.innerHTML = script;

    this._document.head.appendChild(scriptTag);
  }

  deleteActiveCampaignScript() {
    const scriptTag = this._document.getElementById("activeCampaign");

    if (scriptTag) {
      scriptTag.remove();
    }
  }
}
