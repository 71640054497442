<li class="menu-item" aria-haspopup="true">
  <a
    href="javascript:"
    [routerLink]="['/' + navigateTo]"
    routerLinkActive="active"
    class="menu-link"
    (click)="commonService.showSidenav(); navigateToUrl(navigateTo)"
    #routerLinkActive="routerLinkActive"
  >
    <span class="menu-text">
      <span class="">
        @if (!routerLinkActive.isActive) {
          <img
            ngSrc="assets/svg/side-nav-items/{{ image }}-not-active.svg"
            alt=""
            height="18"
            width="18"
          />
        }
        @if (routerLinkActive.isActive) {
          <img
            ngSrc="assets/svg/side-nav-items/{{ image }}-active.svg"
            alt=""
            height="18"
            width="18"
          />
        }
      </span>
      {{ label }}
    </span>
  </a>
</li>
