import { TranslateService } from "@services";
import { capitalizeFirstLetter } from "@utils";
import type { ChallengePhase, VoucherAction, Voucher } from "@models";

enum ProfitTarget {
  LOW = 5,
  MEDIUM = 8,
  HIGH = 10,
}

export const phaseLabel: Record<ChallengePhase, string> = {
  PHASE1: "Phase 1",
  PHASE2: "Phase 2",
  FUNDED: "Funded Phase",
};

export function getVoucherActionsPhasesLabel(action: VoucherAction) {
  return action.appliesToPhases.map((phase) => phaseLabel[phase]).join(", ");
}

function getShare(ratio: number): number {
  return Math.round(ratio * 100);
}

function getPercentage(ratio: number): number {
  const difference = Math.abs(1 - ratio) * 100;

  return Math.round(difference);
}

function getFinalPercentage(ratio: number, percentage: number): number {
  return Math.round(ratio * percentage);
}

function getProfitTarget(appliesToPhases: ChallengePhase[]): number {
  if (appliesToPhases.includes("PHASE2")) {
    return ProfitTarget.LOW;
  }

  return ProfitTarget.MEDIUM;
}

function generateVoucherTitle(
  voucherAction: VoucherAction,
  translateService: TranslateService,
): string {
  const { type, ratio, appliesToPhases } = voucherAction;

  const translations = translateService.getTranslationObject("app.earn.voucherTitle");
  const translation = translations[type];

  switch (type) {
    case "PURCHASE_PRICE_REDUCTION":
      if (ratio === 0) return translations[`${type}_100`];

      return `${getPercentage(ratio)}% ${translation}`;

    case "PROFIT_TARGET_REDUCTION":
      return `${getFinalPercentage(ratio, getProfitTarget(appliesToPhases))}% ${translation}`;

    case "DAILY_LOSS_INCREASE":
      return `${getFinalPercentage(ratio, ProfitTarget.LOW)}% ${translation}`;

    case "TOTAL_LOSS_INCREASE":
      return `${getFinalPercentage(ratio, ProfitTarget.HIGH)}% ${translation}`;

    case "PROFIT_SHARE":
    case "FEE_REFUND_SHARE":
      return `${getShare(ratio)}% ${translation}`;

    case "PAYOUTS_FREQUENCY_INCREASE":
      return translation;

    case "TRADING_DAYS_REDUCTION":
      if (ratio === 0) return translations[`${type}_0`];

      return `${Math.round(ProfitTarget.LOW * ratio)} ${translation}`;

    default:
      return "";
  }
}

export function getVoucherTitle(voucher: Voucher, translateService: TranslateService): string {
  const planet = capitalizeFirstLetter(voucher.validForPlanet);

  return `${voucher.voucherActions.map((voucherAction) => generateVoucherTitle(voucherAction, translateService)).join(" & ")} (${planet})`;
}

export function getVoucherDescription(
  voucher: Voucher,
  translateService: TranslateService,
): string[] {
  const phaseTranslation = translateService.getTranslationObject("app.earn.phase");

  return voucher.voucherActions.map(
    (voucherAction) =>
      `${generateVoucherTitle(voucherAction, translateService)} (${voucherAction.appliesToPhases
        .sort((a, b) => a.localeCompare(b))
        .map((phase) => phaseTranslation[phase])
        .join(", ")})`,
  );
}
