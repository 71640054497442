import { Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from "@angular/forms";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FormsModule],
})
export class InputComponent implements ControlValueAccessor {
  @Input() type: HTMLInputElement["type"] = "text";
  @Input() id = "";
  @Input() name = "";
  @Input() value = "";
  @Input() placeholder = "";
  @Input() label = "";
  @Input() error = "";
  @Input() disabled = false;
  @Input() required = false;
  @Input() readonly = false;

  onChange = (value: unknown) => value;
  // eslint-disable-next-line no-empty-function
  onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
