import { ComponentType } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";

interface DialogData {
  title: string;
  subtitle: string;
  component: ComponentType<unknown>;
}

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, MatDialogModule],
})
export class DialogComponent<T> {
  validatedData: T;

  constructor(
    public dialogRef: MatDialogRef<DialogData>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}
}
