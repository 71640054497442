@if (!!link) {
  <a
    class="link"
    [ngClass]="['link--' + size, 'link--' + type, 'link--' + variant]"
    [href]="link"
    [target]="target"
  >
    <ng-container *ngTemplateOutlet="linkContent" />
  </a>
} @else {
  <a
    class="link"
    [ngClass]="['link--' + size, 'link--' + type, 'link--' + variant]"
    [routerLink]="[routerLink]"
  >
    <ng-container *ngTemplateOutlet="linkContent" />
  </a>
}

<ng-template #linkContent>
  <span class="link__label">{{ label }}</span>
  @if (!!icon) {
    <img class="link__icon" [src]="'assets/svg/icon-' + icon + '.svg'" class="link__icon" alt="" />
  }
</ng-template>
