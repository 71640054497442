import { Injectable, isDevMode } from "@angular/core";
import { Intercom } from "@supy-io/ngx-intercom";
import { environment } from "@env";

@Injectable({ providedIn: "root" })
export class IntercomService {
  constructor(public intercom: Intercom) {}

  boot() {
    if (isDevMode()) return;

    this.intercom.boot({
      app_id: environment.intercomAppId,
    });
  }
}
