import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Auth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  signInWithEmailAndPassword,
  signOut,
} from "@angular/fire/auth";
import { Observable, from } from "rxjs";
import { environment } from "@env";
import type {
  AuthData,
  AuthProvider,
  AuthResponse,
  AuthenticatedUserRegisterData,
  NewUser,
  NewUserWithoutToken,
  RegisterResponse,
  User,
} from "@models";
import type { LegacyResponse } from "./response.model";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Injectable({ providedIn: "root" })
export class AuthService {
  private usersApiUrl = environment.usersApiUrl;

  constructor(
    private httpClient: HttpClient,
    private firebaseAuth: Auth,
    private gtmService: GoogleTagManagerService,
  ) {}

  private getAuthProvider(
    authProvider: AuthProvider,
  ): GoogleAuthProvider | FacebookAuthProvider | OAuthProvider {
    switch (authProvider) {
      case "GOOGLE":
        return new GoogleAuthProvider();
      case "FACEBOOK":
        return new FacebookAuthProvider();
      case "APPLE":
        return new OAuthProvider("apple.com");
      default:
        throw new Error(`Unsupported auth provider: ${authProvider}`);
    }
  }

  login(data: AuthData) {
    return this.httpClient.post<AuthResponse>(`${this.usersApiUrl}/login`, data);
  }

  registerWithEmailAndPassword(newUser: NewUser) {
    return this.httpClient.post<LegacyResponse<RegisterResponse>>(
      `${this.usersApiUrl}/add`,
      newUser,
    );
  }

  registerWithEmailAndPasswordWithoutToken(newUser: NewUserWithoutToken) {
    return this.httpClient.post<LegacyResponse<RegisterResponse>>(
      `${this.usersApiUrl}/add`,
      newUser,
    );
  }

  registerAuthenticatedUser(newUser: AuthenticatedUserRegisterData) {
    return this.httpClient.post<User>(`${this.usersApiUrl}/add-authenticated`, newUser);
  }

  forgotPassword(payload: { userEmail: string }) {
    return this.httpClient.put<LegacyResponse<null>>(
      `${this.usersApiUrl}/forgot-password`,
      payload,
    );
  }

  getUserByEmail(userEmail: string) {
    return this.httpClient.post<LegacyResponse<RegisterResponse>>(`${this.usersApiUrl}/search`, {
      userEmail,
    });
  }

  getUserByUsername(userName: string) {
    return this.httpClient.post<LegacyResponse<RegisterResponse>>(`${this.usersApiUrl}/search`, {
      userName,
    });
  }

  getIdToken() {
    return new Observable<string | null>((observer) => {
      this.firebaseAuth.onAuthStateChanged((user) => {
        if (!user) return observer.next(null);

        return user.getIdToken().then((token) => {
          observer.next(token);
        });
      });
    });
  }

  loginWithEmailAndPassword(email: string, password: string) {
    return from(signInWithEmailAndPassword(this.firebaseAuth, email, password));
  }

  loginWithAuthProvider(authProvider: AuthProvider) {
    const provider = this.getAuthProvider(authProvider);

    provider.addScope("email");

    return from(signInWithRedirect(this.firebaseAuth, provider));
  }

  getDataAfterAuthProviderRedirect() {
    return from(getRedirectResult(this.firebaseAuth));
  }

  isAuthenticated() {
    return new Observable<boolean>((observer) => {
      this.firebaseAuth.onAuthStateChanged((user) => {
        observer.next(!!user);
      });
    });
  }

  logout() {
    const gtmTag = { user: null };
    this.gtmService.pushTag(gtmTag);
    return from(signOut(this.firebaseAuth));
  }
}
