import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import {
  FooterComponent,
  BreadcrumbComponent,
  HeaderComponent,
  SideNavComponent,
} from "@components";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
  standalone: true,
  imports: [SideNavComponent, HeaderComponent, BreadcrumbComponent, RouterOutlet, FooterComponent],
})
export class MainLayoutComponent {}
